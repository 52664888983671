import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/team.scss";
import { team } from "../assests/data";
import { AiOutlineTwitter } from "react-icons/ai";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";

const Teams = () => {
  const [item, setItem] = useState(1);
  const [padding, setPadding] = useState(40);
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    sliderResponsive();
  }, [item]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  function sliderResponsive() {
    if (window.innerWidth > 1000) {
      setItem(4);
    }
    if (window.innerWidth < 1000) {
      setItem(3);
    }
    if (window.innerWidth < 780) {
      setItem(2);
    }
    if (window.innerWidth < 520) {
      setItem(1);
      setPadding(70);
    }
  }
  useEffect(() => {
    sliderResponsive();
  }, []);
  window.addEventListener("resize", () => {
    // sliderResponsive();
  });

  return (
    <>
      <section id="team" className="team">
        <div className="container">
          <div className="heading">
            <h1
              data-aos-delay="300"
              data-aos-easing="ease-out"
              data-aos="zoom-out"
            >
              <span
                style={{
                  WebkitTextStroke: "1px #fff",
                  color: "transparent",
                  fontStyle: "normal",
                  fontWeight: "900",
                }}
              >
                MEET
              </span>{" "}
              THE TEAM
            </h1>
            <p>The ones who are making everything happen.</p>
          </div>
          <div className="team__grid">
            {team.map((ite, ind) => {
              return (
               
               
                  <div key={ind} className="item"      data-aos="fade-up"
                  data-aos-delay={150 * ind}
                  data-aos-easing="ease-out">
                    <div className="inner">
                      <div className="img">
                        <img src={ite.path} alt="" />
                      </div>
                      <div className="detail">
                        <div>
                          {" "}
                          <h3
                            className="name"
                            style={{
                              fontSize: "20px",
                              fontWeight: "700",
                              textTransform: "uppercase",
                            }}
                          >
                            {ite.name}
                          </h3>
                          <h4
                            style={{
                              fontWeight: "200",
                              textTransform: "uppercase",
                              marginTop: "5px",
                            }}
                          >
                            {ite.position}
                          </h4>
                        </div>
                        <a>
                          <AiOutlineTwitter
                            style={{ width: "30px", height: "30px" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
               
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Teams;
