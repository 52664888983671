import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/banner.scss";
import {
  collection,
  collection2,
  collection3,
  collection4,
} from "../assests/data";
import AboutNft from "./AboutNft";
import Navigation from "./Navigation";
import Marquee from "react-easy-marquee";
import { AiOutlineTwitter } from "react-icons/ai";
import { Parallax, Background } from "react-parallax";
import gsap from "gsap";
import { Power4, Back, Expo } from "gsap";

import { Timeline } from "gsap/gsap-core";
import Bg from "./KFS-banner.png";
const Banner = ({ timeline, ease }) => {
  const [img, setimg] = useState("/images/nft/1-.png");
  useEffect(() => {
    AOS.init({});
  }, []);
  // write a function that generate a random number between 1 and 20 after 1 second
  useEffect(() => {
    timeline.from(".topBack", 1, {
      opacity: 1,

      scale: 2,

      ease: Expo.easeOut,
      delay: 0,
      stagger: {
        amount: 0.4,
        scrub: true,
      },
    });
  }, []);

  return (
    <>
      <Navigation />
      <section className="banner">
        <img className="topBack" src="/images/Background/KFS-banner.png" alt="" />
      </section>{" "}
    </>
  );
};

export default Banner;
