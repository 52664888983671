import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/aboutnft.scss";
import Marquee from "react-fast-marquee";
const AboutNft = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      offset: 100,
    });
  }, []);

  return (
    <>
      <div className="mar">
        <Marquee
          Reverse={false}
          speed={100}
          background="transparent"
          height="60px"
          gradient={false}
        >
          {[1, 2, 3, 4, 5, 6].map((item, index) => {
            return (
              <h1 style={{ textTransform: 'uppercase',
              marginRight: '20px' }}>
               A collection of kangaroos fighting for the cardano ecosystem!
              </h1>
            );
          })}
        </Marquee>
      </div>
      <section id="about" className="about">
        <div className="container">
          <div className="about__grid">
            <div
              className="img"
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-easing="ease-out"
            >
              <figure>
                <img src="/images/nft/5-.png" alt="" />
                <img src="/images/nft/4-.png" alt="" />
              </figure>
            </div>
            <div className="details">
              <div>
                <h2 data-aos="fade-up" data-aos-delay="300">
                Kangaroo Fight Society
                </h2>
              </div>
              <p data-aos="fade-up" data-aos-delay="300">
                <span>
                  A total of 5112 combative kangaroos living on the Cardano
                  blockchain. We are a fighting society but that does not mean
                  we will be fighting against others but rather, fighting for
                  others. We believe in Cardano and its ever-expanding ecosystem
                  and have the utmost respect for the projects who are seriously
                  building some spectacular things and have stuck around through
                  turbulent times. We are focused on giving back, not only to
                  our holders but to the cardano community and charitable
                  organizations as well.
                </span>{" "}
                <span>
                  Instead of us making our own native coin we will be investing
                  in other well established projects native coins. We at KFS
                  believe this will not only benefit our holders with exposure
                  to other established projects, but also benefit the other
                  projects as well because we will be adding liquidity to their
                  native tokens/assets.
                </span>
                <span>
                  We are all stronger together, and together we can push this
                  space to the limit and beyond
                </span>
              </p>
              <a
                style={{ display: "inline-block" }}
                href="https://twitter.com/KangarooFS_NFT"
                target={"_blank"}
              >
                <button>Join Community!</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutNft;
