import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/Collection.scss";
import Marquee from "react-easy-marquee";
import { Nfts,Nfts_2 } from "../assests/data";
const Collection = () => {
  const [height, setHeight] = useState();
  window.addEventListener("scroll", () => {
    setHeight((window.scrollY / 4918) * 100);



  });
  return (
    <>
      <section className="collection" id="collection">
        <div className="line">
          <span></span>
        </div>

        <div
          className="img__grid"
          style={{ transform: `translateX(${height}%)`,marginLeft:'-800px' }}
        >
          {Nfts.map((item, index) => {
            return <img src={item} alt="collection" />;
          })}
        </div>
        <div
          className="img__grid"
          style={{ transform: `translateX(${-height}%)` ,marginLeft:'0px'}}
        >
          {Nfts_2.map((item, index) => {
            return <img src={item} alt="collection" />;
          })}
        </div>
      </section>
    </>
  );
};

export default Collection;
