import React, { useState, useEffect, useRef } from "react";
import "./scss/normalize.css";
import Banner from "./components/Banner";
// import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Roadmap from "./components/Roadmap";
import Teams from "./components/Teams";
import Faq from "./components/Faq";
import Collection from "./components/Collection";
import "./scss/reset.css";
import AboutNft from "./components/AboutNft";
import Info from "./components/Info";
import Loader from "./components/Loader";
import { gsap } from "gsap";
import { TimelineLite, Power4 } from "gsap/gsap-core";


var Spinner = require("react-spinkit");
function App() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#fff");
  const containerRef = useRef(null);
  const tl = new gsap.timeline();
  let ease = Power4.easeOut();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <div className="App">
        {loading ? (
          <Loader setLoading={setLoading} />
        ) : (
          <>
            <main>
              <Banner timeline={tl} ease={ease} />
              <AboutNft />
              <Collection />
              <Roadmap />
              <Faq />
              <Teams />
              <Footer />
            </main>
          </>
        )}
      </div>
    </>
  );
}

export default App;
