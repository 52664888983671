import React, { useEffect,useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/roadmap.scss";
import { roadmap } from "../assests/data";

const Roadmap = ({ language }) => {
  const [nft, setnft] = useState(null)
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
  }, []);
  window.addEventListener("scroll", () => {
    setnft(Math.floor((window.scrollY / 4918) * 10));
// console.log(nft);
  });
  return (
    <>
      <section id="roadmap" className="roadmap">
        <div className="heading">
          <h1
            data-aos-delay="300"
            data-aos-easing="ease-out"
            data-aos="zoom-out"
          >
            <span
              style={{
                WebkitTextStroke: "1px #fff",
                color: "transparent",
                fontStyle: "normal",
                fontWeight: "900",
              }}
            >
              ROAD
            </span>{" "}
            MAP
          </h1>

        </div>
        <div className="container">
          <div className="roadmap__grid">
            <div className="end">
              {roadmap.map((item, i) => {
                return (
                  <div className="item" data-aos='fade-up'>
                    <h2>Phase {item.id}</h2>
                    <ul>
                      {item.points.map((item, i) => {
                        return <li key={i}>{item}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
            <div className="start">
              <div className="img">
                <img
                  data-aos="zoom-out-up"
                  data-aos-easing="ease-out"
                  src={`/images/nft/${nft}.png`}
                  width="100%"
                  alt=""
                />
              </div>
          
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
