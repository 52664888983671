import { FaDiscord } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaRedditAlien } from "react-icons/fa";
export const nav = [
  {
    name: "About Us",
    path: "#about",
  },
  {
    name: "Roadmap",
    path: "#roadmap",
  },

  {
    name: "Collection",
    path: "#collection",
  },
  {
    name: "Our Team",
    path: "#team",
  },
];

export const roadmap = [
  {
    id: "01",
    points: [
      "Launch discord and twitter",
      "Building community and establishing whitelist (see #how-to-whitelist in discord)",
      "Explore collaborations/partnerships with other projects in the Cardano/CNFT Ecosystem.",
      "Launch Finished Website.",
      "Launch the collection of 5,100 Kangaroos onto the web: October 2022.",
    ],
    date: "2022",
    img: "/images/roadmap/1.png",
  },
  {
    id: "02",
    points: [
      "We believe in Cardano and its ever-expanding ecosystem and have the utmost respect for the projects who are seriously building some spectacular things and have stuck around through turbulent times. Instead of making our own native coin we will be investing in other well established projects native coins. We at KFS believe this will not only benefit our holders with exposure to other established projects, but also benefit the other projects as well because we will be adding liquidity to their native tokens/assets. ",
      "We will be rewarding holders with exclusive airdrops of these native coins/assets that are purchased. This can be done in a variety of methods such as: Raffles, Giveaways, Games, Holder (delisted) air drops, etc.",
      "Official Quality KFS Merchandise.",
      "Begin development of our game (more info to come).",
    ],
    date: "2022",
    img: "/images/roadmap/1.png",
  },
  {
    id: "03",
    points: [
      "Make our first official donation to a charitable cause (kangaroo wildlife/martial arts related) using a portion of royalties collected from secondary market sales. ",
      "Create partnerships and sponsorships with athletes, and martial arts clubs around the world. ",
      "Continue our investing into more established Cardano native projects to help provide liquidity for projects and reward and distribute to KFS holders.     ",
      "Acquire land/assets in Cardano native metaverse projects in order to support and build a homebase for our Kangaroo Fight Society members to socialize and partake in all the events and challenges the metaverse has to offer. ",
      "Development of our second KFS branded collection (more info TBA)    ",
    ],
    date: "2022",
    img: "/images/roadmap/1.png",
  },
  {
    id: "04",
    points: [
      "Beta Test and launch game",
      "TBA- We have some very cool ideas in store for phase 4 and beyond. ",
    ],
    date: "2022",
    img: "/images/roadmap/1.png",
  },
];


export const FAQ = [
  {
    q: "how many collectible Kangaroos are there?",
    ans: ["There is a limited collection of 5112 OG Kangaroo Fight Society NFTs on the Cardano blockchain."],
  },


  {
    q: " Wen Mint?",
    ans: [
      "Mint is scheduled for October 2022 (more details on exact date to come)",
    ],
  },
  {
    q: "What native CNFT tokens/projects will you be investing in?",
    ans: [
      "We have a short list in mind, think the 'blue chips' and plan to allocate a smaller portion to some up and coming CNFT projects. We will not be saying exact names now because we do not want to be front run.",
    ],
  },
  {
    q: " How will holders be rewarded?    ",
    ans: [
      "Holders will be rewarded with ADA and native tokens that are farmed through liquidity pairs. These rewards can be won/claimed in a variety of methods such as airdrops, giveaways,contests, etc. You will also secure a spot for upcoming 2nd generation KFS NFT drops.",
    ],
  },
];

export const team = [
  {
    name: "Boss Roo - Steu   ",
    position: " Founder",
    path: "/images/team/1.png",
    link: "twitter.com/shoto_j",
  },
  {
    name: " Down Under Roo - Zach   ",
    position: " Co-Founder",
    path: "/images/team/5.png",
    link: "",
  },
  {
    name: " Kangaroo Jack    ",
    position: "Marketing ",
    path: "/images/team/9.png",
    link: "twitter.com/shoto_j",
  },
];
export const Nfts = [
  "/images/nft/1.png",
  "/images/nft/2.png",
  "/images/nft/3.png",
  "/images/nft/4.png",
  "/images/nft/5.png",
  "/images/nft/7.png",
  "/images/nft/8.png",
  "/images/nft/9.png",
  "/images/nft/10.png",
];


export const Nfts_2 = [
  
  "/images/nft/8.png",
  "/images/nft/9.png",
  "/images/nft/10.png",
  "/images/nft/11.png",
  "/images/nft/12.png",
  "/images/nft/13.png",
  "/images/nft/14.png",
  "/images/nft/15.png",
  "/images/nft/1.png",
  "/images/nft/2.png",
];
